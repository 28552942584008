import React, { useState, useEffect } from "react";
import classnames from "classnames";
import css from "./select-list.module.css";

const SelectList = ({
  title,
  choices,
  selected,
  setSelected,
  overlay,
  setOverlay,
  defaultText,
  icons,
  disabled,
  hide,
}) => {
  const [open, setOpen] = useState(false);
  const [over, setOver] = useState();
  const [selectedChoice] = choices.filter((item) => item.code === selected);
  const selectedIcon = icons ? "flags/" + selectedChoice.iso_code + ".svg" : "";

  const openList = (e) => {
    if (!disabled) {
      setOpen(!open);
      setOverlay(!overlay);
    }
  };
  const selectionChanged = (code) => (e) => {
    setOpen(!open);
    setOverlay(!overlay);
    setSelected(code);
  };
  const overChanged = (name) => (e) => {
    setOver(name);
  };

  const selectionText = classnames(css.selectedText, {
    [css.defaultText]: !selected,
  });
  const component = classnames(css.component, {
    [css.disabled]: disabled,
    [css.hide]: hide,
  });
  const selectList = classnames(css.selectList, {
    [css.cursor]: disabled,
  });

  useEffect(() => {
    const element = document.getElementById(selected);
    if (element) {
      element.scrollIntoView();
    }
  }, [selected, open]);

  return (
    <div className={component}>
      <div className={css.selector}>
        <div className={css.title}>{title}</div>
        <div className={selectList} onClick={openList}>
          {selectedIcon && (
            <img className={css.icon} src={selectedIcon} alt="" />
          )}
          <span className={selectionText}>
            {selected ? selectedChoice.name : defaultText}
          </span>
          <img
            className={css.foldingArrow}
            src="svg/folding-arrow.svg"
            alt="Choose"
          />
        </div>
      </div>

      {open && (
        <div className={css.optionList}>
          <div className={css.selectionTitle}>
            <span>Sélectionner un {title.toLowerCase()}</span>
            <img
              className={css.cross}
              src="svg/cross.svg"
              alt="Close"
              onClick={openList}
            />
          </div>
          <div className={css.scrollable}>
            {choices.map(({ name, code, iso_code }) => {
              const iconPath = icons ? "flags/" + iso_code + ".svg" : "";
              const itemClasses = classnames(css.option, {
                [css.selected]: over === code || selected === code,
              });

              return (
                <div
                  id={code}
                  className={itemClasses}
                  key={code}
                  onClick={selectionChanged(code)}
                  onMouseOver={overChanged(code)}
                >
                  {iconPath ? (
                    <img className={css.icon} src={iconPath} alt="" />
                  ) : (
                    <span className={css.textCode}>
                      {String(code).padStart(2, "0")}
                    </span>
                  )}
                  <span className={css.text}>{name}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectList;
