import { post } from "./fetcher";
import { withConfiguration } from "./config";

const api = "my-esf-api";

export const predictResorts = async (body) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config[api]}/api/resort-prediction`;
    return post(endpoint, body);
  });
};
