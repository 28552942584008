import React, { useState } from "react";
import ScreenSlopeDifficulty from "./components/screen-slope-difficulty";
import ScreenKmPistes from "./components/screen-km-pistes";
import ScreenAltitudeMax from "./components/screen-altitude-max";
import ScreenResorts from "./components/screen-resorts";
import ScreenBudgetPerson from "./components/screen-budget-person";
import ScreenChildren from "./components/screen-children";
import ScreenCountry from "./components/screen-country";
import ScreenDate from "./components/screen-date";
import ScreenFirstPage from "./components/screen-first-page";
import css from "./app.module.css";

// import { IntlProvider } from React-intl
// import { useIntl } from React-intl

function App() {
  const [screen, setScreen] = useState(0);
  const [slopeDifficulty, setSlopeDifficulty] = useState();
  const [kmPiste, setKmPiste] = useState();
  const [altitudeMax, setAltitudeMax] = useState();
  const [stayWeek, setStayWeek] = useState();
  const [deltaOrder, setDeltaOrder] = useState();
  const [budgetPerson, setBudgetPerson] = useState();
  const [country, setCountry] = useState();
  const [children, setChildren] = useState();

  const previousScreen = () => {
    setScreen(screen - 1);
  };
  const nextScreen = () => {
    setScreen(screen + 1);
  };
  const redoTest = () => {
    setScreen(0);
    setSlopeDifficulty(undefined);
    setKmPiste(undefined);
    setAltitudeMax(undefined);
    setStayWeek(undefined);
    setDeltaOrder(undefined);
    setBudgetPerson(undefined);
    setCountry(undefined);
    setChildren(undefined);
  };
  const getResort = () => {
    if (country.departement > 96)
      setCountry({
        ...country,
        departement: Math.floor(country.departement / 10),
      });
    setScreen(screen + 1);
  };

  return (
    <div className={css.app}>
      <div className={css.window}>
        {screen === 0 && <ScreenFirstPage nextScreen={nextScreen} />}

        {screen === 1 && (
          <ScreenSlopeDifficulty
            slopeDifficulty={slopeDifficulty}
            setSlopeDifficulty={setSlopeDifficulty}
            nextScreen={nextScreen}
            gotoNext={slopeDifficulty !== undefined}
          />
        )}
        {screen === 2 && (
          <ScreenKmPistes
            kmPiste={kmPiste}
            setKmPiste={setKmPiste}
            nextScreen={nextScreen}
            previousScreen={previousScreen}
            gotoNext={kmPiste !== undefined}
          />
        )}
        {screen === 3 && (
          <ScreenAltitudeMax
            altitudeMax={altitudeMax}
            setAltitudeMax={setAltitudeMax}
            nextScreen={nextScreen}
            previousScreen={previousScreen}
            gotoNext={altitudeMax !== undefined}
          />
        )}
        {screen === 4 && (
          <ScreenDate
            stayWeek={stayWeek}
            setStayWeek={setStayWeek}
            setDeltaOrder={setDeltaOrder}
            nextScreen={nextScreen}
            previousScreen={previousScreen}
            gotoNext={stayWeek !== undefined}
          />
        )}
        {screen === 5 && (
          <ScreenBudgetPerson
            budgetPerson={budgetPerson}
            setBudgetPerson={setBudgetPerson}
            nextScreen={nextScreen}
            previousScreen={previousScreen}
            gotoNext={budgetPerson !== undefined}
          />
        )}
        {screen === 6 && (
          <ScreenCountry
            selected={country}
            setSelected={setCountry}
            nextScreen={nextScreen}
            previousScreen={previousScreen}
            gotoNext={country !== undefined}
          />
        )}
        {screen === 7 && (
          <ScreenChildren
            children={children}
            setChildren={setChildren}
            nextScreen={getResort}
            previousScreen={previousScreen}
          />
        )}
        {screen === 8 && (
          <ScreenResorts
            slopeDifficulty={slopeDifficulty}
            kmPiste={kmPiste}
            altitudeMax={altitudeMax}
            stayWeek={stayWeek}
            deltaOrder={deltaOrder}
            budgetPerson={budgetPerson}
            country={country}
            children={children}
            redoTest={redoTest}
          />
        )}
      </div>
    </div>
  );
}

export default App;
