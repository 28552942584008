import React from "react";
import css from "./resort-result.module.css";
import { useIntl } from "react-intl";

const ResortResult = ({
  score,
  photo,
  name,
  region,
  altitudeMin,
  altitudeMax,
  slopesTotalLength,
  url,
}) => {
  const intl = useIntl();

  const sizeProgressBar = String(Math.round(score * 100)).concat("%");
  const nameSplited = name.split(" ");
  const matchingText = intl.messages.resortResult.detail1.toUpperCase();

  return (
    <div className={css.component}>
      <img className={css.image} src={photo} alt="" />
      <div className={css.correspondence}>
        <span>{sizeProgressBar}</span> <span>{matchingText}</span>
      </div>
      <div className={css.progressBar}>
        <div
          className={css.filledProgressBar}
          style={{ width: sizeProgressBar }}
        />
      </div>
      <div className={css.nameContainer}>
        {nameSplited.map((word, index) => {
          return (
            <div className={css.resortName} key={index}>
              {word}
            </div>
          );
        })}
      </div>
      <div className={css.properties}>{region}</div>
      <div className={css.properties}>
        {altitudeMin}m - {altitudeMax}m
        <span className={css.propertiesUnit}>
          {intl.messages.resortResult.detail2}
        </span>
      </div>
      <div className={css.properties}>
        {slopesTotalLength}km
        <span className={css.propertiesUnit}>
          {intl.messages.resortResult.detail3}
        </span>
      </div>
      <div className={css.urlBox}>
        <a
          className={css.url}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.messages.resortResult.detail4}
        </a>
      </div>
    </div>
  );
};

export default ResortResult;
