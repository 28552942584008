import React, { useEffect, useState } from "react";
import ResortResult from "./resort-result";
import Header from "./header";
import css from "./screen.module.css";
import classnames from "classnames";
import { useIntl } from "react-intl";
import { predictResorts } from "../services/api";

const ScreenResorts = ({
  slopeDifficulty,
  kmPiste,
  altitudeMax,
  stayWeek,
  deltaOrder,
  budgetPerson,
  country,
  children,
  redoTest,
}) => {
  const [resorts, setResorts] = useState();

  const intl = useIntl();

  useEffect(() => {
    const getData = async () => {
      const body = {
        slopeDifficulty,
        kmSlopes: kmPiste,
        altitudeMax,
        stayWeek: stayWeek + 30,
        deltaOrder,
        budgetPerson,
        country: country.country,
        departement: country.departement ? country.departement : undefined,
        children,
      };
      const results = await predictResorts(body);

      setResorts(results);
    };

    getData();
  }, [
    slopeDifficulty,
    kmPiste,
    altitudeMax,
    stayWeek,
    deltaOrder,
    budgetPerson,
    country,
    children,
  ]);

  return (
    <div className={css.screen}>
      <Header
        num={8}
        headerImage={intl.messages.resortResult.img}
        imgDescription={intl.messages.resortResult.imgDescription}
        lastScreen={true}
        redoTest={redoTest}
      />
      <div className={classnames(css.body, css.resortScreen)}>
        {resorts === undefined && <div>Loading...</div>}
        {resorts &&
          resorts.map((resort, index) => {
            return <ResortResult {...resort} key={index} />;
          })}
      </div>
    </div>
  );
};

export default ScreenResorts;
