import React from "react";
import css from "./header.module.css";
import classnames from "classnames";
import { useIntl } from "react-intl";

const Header = ({
  num,
  question,
  detail,
  headerImage,
  imgDescription,
  nextScreen,
  previousScreen,
  gotoNext,
  lastScreen,
  redoTest,
}) => {
  const intl = useIntl();

  const gotoPrev = num !== 1;
  const label = String(num).padStart(2, "0");
  const percentage = String(Math.round(((num - 1) / 7) * 100)).concat("%");
  const buttonsState = [
    { state: gotoPrev, img: "svg/chevron-prev.svg", onClick: previousScreen },
    { state: gotoNext, img: "svg/chevron-next.svg", onClick: nextScreen },
  ];

  const redoText = intl.messages.buttons.redo.toUpperCase();
  const completionText = intl.messages.buttons.completion.toUpperCase();

  return (
    <div className={css.header}>
      {num !== -1 && (
        <div className={css.progressBar}>
          <div
            className={css.progressBarFilled}
            style={{ width: percentage }}
          />
        </div>
      )}

      <div className={css.head}>
        {num === 8 && (
          <div className={css.redoTest} onClick={redoTest}>
            <img className={css.redoIcon} src="svg/redo.svg" alt="" />
            {redoText}
          </div>
        )}
        <div className={css.topLeft}>
          {num !== -1 && (
            <div className={css.completion}>
              <span>{percentage}</span> <span>{completionText}</span>
            </div>
          )}
          {!lastScreen &&
            buttonsState.map(({ state, img, onClick }, index) => {
              const buttonStyle = classnames(css.button, {
                [css.disabled]: !state,
              });
              return (
                <div
                  key={index}
                  className={buttonStyle}
                  onClick={state ? onClick : undefined}
                >
                  <img src={img} alt="" />
                </div>
              );
            })}
        </div>
      </div>
      {headerImage && (
        <img
          className={css.headerImage}
          src={headerImage}
          alt={imgDescription}
        />
      )}
      {num !== -1 && num !== 8 && (
        <div className={css.numBox}>
          <span className={css.num}>{label}</span>
        </div>
      )}
      {question && <div className={css.question}> {question} </div>}
      {detail && <div className={css.detail}> {detail} </div>}
      <div className={css.line} />
    </div>
  );
};

export default Header;
