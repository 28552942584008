import React from "react";
import classnames from "classnames";
import css from "./multi-choices-selector.module.css";

const MultiChoicesSelector = ({ choices, selected, setSelected }) => {
  const selectionChanged = (index) => (e) => {
    setSelected(index);
  };

  return (
    <div className={css.selector}>
      {choices.map(({ name, image }, index) => {
        const letter = String.fromCharCode(index + 65);
        const itemClasses = classnames(css.item, {
          [css.selected]: selected.indexOf(index) !== -1,
        });
        return (
          <div
            key={index}
            className={itemClasses}
            onClick={selectionChanged(index)}
          >
            <img className={css.check} src="svg/check.svg" alt="" />
            <div className={css.imageBox}>
              <img className={css.image} src={image} alt="" />
            </div>
            <div className={css.captionBox}>
              <div className={css.letterOuter}>
                <span className={css.letter}>{letter}</span>
              </div>
              <span className={css.name}>{name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MultiChoicesSelector;
