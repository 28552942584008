import React, { useState, useEffect } from "react";
import Selector from "./selector";
import css from "./screen.module.css";
import classnames from "classnames";
import Header from "./header";
import { useIntl } from "react-intl";
import useWindowSize from "../hooks/useWindowSize";

const letters = [
  {
    code: 3,
    s_top: "52px",
    s_left: "209px",
    l_top: "67px",
    l_left: "269px",
  },
  {
    code: 2,
    s_top: "131px",
    s_left: "218px",
    l_top: "169px",
    l_left: "282px",
  },
  {
    code: 1,
    s_top: "208px",
    s_left: "229px",
    l_top: "268px",
    l_left: "295px",
  },
  {
    code: 0,
    s_top: "282px",
    s_left: "213px",
    l_top: "363px",
    l_left: "276px",
  },
];

const ScreenAltitudeMax = ({
  altitudeMax,
  setAltitudeMax,
  nextScreen,
  previousScreen,
  gotoNext,
}) => {
  const [mode, setMode] = useState();
  const [over, setOver] = useState();

  const intl = useIntl();
  const size = useWindowSize();

  useEffect(() => {
    setMode(size.width >= 1200 ? "Desktop" : "Tablet");
  }, [size.width]);

  return (
    <div className={css.screen}>
      <Header
        num={3}
        question={intl.messages.altitudeMax.question}
        detail=""
        nextScreen={nextScreen}
        previousScreen={previousScreen}
        gotoNext={gotoNext}
      />

      <div>
        <img className={css.pingImage} src="ping/ping-fly.svg" alt="" />
      </div>
      <div className={css.body}>
        <div className={css.stationImage}>
          <img
            className={classnames(css.montain, css.open)}
            src="station/station-empty.svg"
            alt=""
          />
          {letters.map(({ code, s_top, s_left, l_top, l_left }, index) => {
            const letter = String.fromCharCode(index + 65);
            const itemClasses = classnames(css.letters, {
              [css.selected]: altitudeMax === code || over === code,
            });
            const top = mode === "Desktop" ? l_top : s_top;
            const left = mode === "Desktop" ? l_left : s_left;
            return (
              <div
                key={code}
                className={itemClasses}
                style={{ top: top, left: left }}
              >
                {letter}
              </div>
            );
          })}
        </div>
        <Selector
          choices={intl.messages.altitudeMax.selector}
          selected={altitudeMax}
          setSelected={setAltitudeMax}
          over={over}
          setOver={setOver}
          nextScreen={nextScreen}
        />
      </div>
    </div>
  );
};

export default ScreenAltitudeMax;
