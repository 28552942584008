import React, { useState } from "react";
import Selector from "./selector";
import css from "./screen.module.css";
import Header from "./header";
import { useIntl } from "react-intl";

const ScreenSlopeDifficulty = ({
  slopeDifficulty,
  setSlopeDifficulty,
  nextScreen,
  gotoNext,
}) => {
  const [over, setOver] = useState();

  const intl = useIntl();

  return (
    <div className={css.screen}>
      <Header
        num={1}
        question={intl.messages.skiLevel.question}
        detail={intl.messages.skiLevel.detail}
        nextScreen={nextScreen}
        gotoNext={gotoNext}
      />
      <div>
        <img className={css.pingImage} src="ping/ping-sit.svg" alt="" />
      </div>
      <div className={css.body}>
        <Selector
          choices={intl.messages.skiLevel.selector}
          selected={slopeDifficulty}
          setSelected={setSlopeDifficulty}
          over={over}
          setOver={setOver}
          nextScreen={nextScreen}
        />
      </div>
    </div>
  );
};

export default ScreenSlopeDifficulty;
