import React, { useState, useEffect } from "react";
import Button from "./button";
import MultiChoicesSelector from "./multi-choices-selector";
import css from "./screen.module.css";
import Header from "./header";
import { useIntl } from "react-intl";
import useWindowSize from "../hooks/useWindowSize";

const add = (selected, index) => {
  const noChildren = selected.indexOf(3) !== -1;
  const head =
    index === 3
      ? []
      : noChildren
      ? selected.filter((item) => item !== 3)
      : selected;
  return [...head, index];
};

const remove = (selected, index) => {
  return selected.filter((item) => item !== index);
};

const ScreenChildren = ({
  children,
  setChildren,
  nextScreen,
  previousScreen,
  gotoNext,
}) => {
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState();

  const intl = useIntl();
  const childrenOption = intl.messages.children.selector;
  const size = useWindowSize();

  useEffect(() => {
    setMode(size.width > 410 ? "Desktop" : "Mobile");
  }, [size.width]);

  const buttonText = mode === "Mobile" ? "Suivant" : "Afficher vos stations";

  const changeSelection = (index) => {
    const position = selected.indexOf(index);
    const addMode = position === -1;
    const action = addMode ? add : remove;
    setSelected(action(selected, index));
  };

  const sumChildrenOption = () => {
    return selected.reduce((acc, item) => childrenOption[item].value + acc, 0);
  };
  const validateChoices = (code) => {
    setChildren(sumChildrenOption());
    nextScreen();
  };

  return (
    <div className={css.screen}>
      <Header
        num={7}
        question={intl.messages.children.question}
        detail={intl.messages.children.detail}
        nextScreen={nextScreen}
        previousScreen={previousScreen}
        gotoNext={gotoNext}
      />
      <div className={css.columnBody}>
        <MultiChoicesSelector
          choices={childrenOption}
          selected={selected}
          setSelected={changeSelection}
        />
        <Button
          text={buttonText}
          onClick={validateChoices}
          disabled={selected.length === 0}
        />
      </div>
    </div>
  );
};

export default ScreenChildren;
