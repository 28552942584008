import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { IntlProvider } from "react-intl";

import en from "./locales/en.json";
import fr from "./locales/fr.json";

ReactDOM.render(
  <IntlProvider locale="fr" messages={fr}>
    <App />
  </IntlProvider>,
  document.getElementById("root")
);
