import React, { useState } from "react";
import Selector from "./selector";
import css from "./screen.module.css";
import Header from "./header";
import { useIntl } from "react-intl";

const ScreenBudgetPerson = ({
  budgetPerson,
  setBudgetPerson,
  nextScreen,
  previousScreen,
  gotoNext,
}) => {
  const [over, setOver] = useState();

  const intl = useIntl();

  return (
    <div className={css.screen}>
      <Header
        num={5}
        question={intl.messages.budgetPerson.question}
        detail=""
        nextScreen={nextScreen}
        previousScreen={previousScreen}
        gotoNext={gotoNext}
      />
      <div>
        <img className={css.pingImage} src="ping/ping-cash.svg" alt="" />
      </div>
      <div className={css.body}>
        <Selector
          choices={intl.messages.budgetPerson.selector}
          selected={budgetPerson}
          setSelected={setBudgetPerson}
          over={over}
          setOver={setOver}
          nextScreen={nextScreen}
          symbol="svg/euro.svg"
        />
      </div>
    </div>
  );
};

export default ScreenBudgetPerson;
