import React, { useState } from "react";
import classnames from "classnames";
import css from "./date-picker.module.css";
import { useIntl } from "react-intl";

const getPrevSaturday = (...args) => {
  const clone = new Date(...args);
  const day = 1 + clone.getDay();
  const prevSaturday = clone.getDate() - (day % 7);
  clone.setDate(prevSaturday);
  return clone;
};

const addDays = (date, days) => {
  const clone = new Date(date);
  clone.setDate(clone.getDate() + days);
  return clone;
};

const createDateList = (startDate, endDate) => {
  const dateList = [startDate];
  while (dateList[dateList.length - 1] < endDate) {
    const newDate = addDays(dateList[dateList.length - 1], 7);
    dateList.push(newDate);
  }
  return dateList;
};

const getVacation = (vacationNames, vacationIndexes, currentIndex) => {
  const index = vacationIndexes.findIndex((idx) => idx === currentIndex);
  return index !== -1 ? vacationNames[index] : "";
};

const formatDate = (date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yy = date.getFullYear();
  return `${dd}/${mm}/${yy}`;
};

const getWeekNumber = (dateList, actualMonth) => {
  const counts = {};
  dateList.forEach((x) => {
    counts[x.getMonth()] = (counts[x.getMonth()] || 0) + 1;
  });
  return counts[actualMonth] || 0;
};

const getDeltaOrder = (stayWeek, now) => {
  const diffTime = Math.abs(stayWeek - getPrevSaturday(now));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays / 7;
};

const seasonMonths = [11, 0, 1, 2, 3];
const vacationIndexes = [2, 10, 18];

const DatePicker = ({ selected, setSelected, setDeltaOrder, mode }) => {
  const [over, setOver] = useState();

  const intl = useIntl();
  const monthNames = intl.messages.date.monthNames;
  const vacationNames = intl.messages.date.vacationNames;

  const now = new Date();
  const seasonYear =
    now < new Date(now.getFullYear(), 4, 1)
      ? now.getFullYear()
      : now.getFullYear() + 1;
  const seasonStart = getPrevSaturday(seasonYear - 1, 11, 25);
  const seasonEnd = new Date(seasonYear, 3, 24);
  const dateList = createDateList(addDays(seasonStart, -14), seasonEnd);
  const selectedDate =
    selected || selected === 0
      ? formatDate(addDays(seasonStart, 7 * selected))
      : "../../....";

  const [monthSelected, setMonthSelected] = useState(
    selected
      ? dateList[selected + 2].getMonth()
      : addDays(seasonStart, -14) < now && now < seasonEnd
      ? now.getMonth()
      : 11
  );
  const actualMonth = monthNames[monthSelected].concat(
    " ",
    monthSelected === 11 ? seasonYear - 1 : seasonYear
  );

  const boudariesItem = classnames(
    css.boudariesItem,
    css.datePickerItem,
    css.datePickerDeactivated,
    {
      [css.tooManyItem]:
        mode === "Mobile" && getWeekNumber(dateList, monthSelected) > 4,
    }
  );
  const leftArrow = classnames(css.arrowIcon, {
    [css.deactivateIcon]: monthSelected === 11,
  });
  const rightArrow = classnames(css.arrowIcon, {
    [css.deactivateIcon]: monthSelected === 3,
  });

  const overChanged = (index) => (e) => {
    setOver(index ? index : undefined);
  };
  const selectionChanged = (index) => (e) => {
    if (index !== undefined) {
      setSelected(index - 2);
      setDeltaOrder(getDeltaOrder(dateList[index], getPrevSaturday(now)));
    }
  };
  const monthSelectionChanged = (month) => (e) => {
    const pos = seasonMonths.indexOf(
      month === 12 ? 0 : month === -1 ? 11 : month
    );
    if (pos !== -1) setMonthSelected(seasonMonths[pos]);
  };

  return (
    <div className={css.component}>
      <div className={css.descriptionBox}>
        <img className={css.calendarIcon} src="svg/calendar.svg" alt="" />
        <span>{intl.messages.date.description}</span>
        <span className={css.dateBox}>{selectedDate}</span>
      </div>
      {mode === "Mobile" && (
        <div className={css.monthSelection}>
          {seasonMonths.map((month) => {
            const monthName = monthNames[month]
              .substring(0, 3)
              .concat(".")
              .toUpperCase();
            const monthStyle = classnames(css.monthChoiceText, {
              [css.selectedMonth]: month === monthSelected,
            });

            return (
              <div
                key={month}
                className={monthStyle}
                onClick={monthSelectionChanged(month)}
              >
                {monthName}
              </div>
            );
          })}
        </div>
      )}
      {mode === "Mobile" && (
        <div className={css.monthSelector}>
          <img
            className={leftArrow}
            onClick={monthSelectionChanged(monthSelected - 1)}
            src="svg/left-arrow.svg"
            alt=""
          />
          <div className={css.actualMonthText}>{actualMonth}</div>
          <img
            className={rightArrow}
            onClick={monthSelectionChanged(monthSelected + 1)}
            src="svg/right-arrow.svg"
            alt=""
          />
        </div>
      )}
      <div className={css.dateBar} onMouseOut={overChanged(undefined)}>
        <div className={boudariesItem}></div>

        {mode !== undefined &&
          dateList.map((date, index) => {
            const dd = String(date.getDate()).padStart(2, "0");
            const mm = String(date.getMonth() + 1).padStart(2, "0");
            const monthName =
              date.getDate() <= 7 || index === 0
                ? monthNames[date.getMonth()].substring(0, 3).concat(".")
                : "";
            const yearName =
              (date.getMonth() === 0 && date.getDate() <= 7) || index === 0
                ? String(date.getFullYear())
                : "";
            const oldDate = now > date;
            const datePickerItem = classnames(css.datePickerItem, {
              [css.datePickerDeactivated]: oldDate,
              [css.datePickerSelected]:
                over === index || selected + 2 === index,
            });
            const vacationBox = classnames(css.vacationBox, {
              [css.vacationBoxDeactivated]: oldDate,
            });
            const vacationName = getVacation(
              vacationNames,
              vacationIndexes,
              index
            ).toUpperCase();
            const showDate =
              mode === "Desktop" || monthSelected === date.getMonth();

            return (
              <div key={index}>
                {showDate && (
                  <div className={css.item}>
                    {mode === "Desktop" && (
                      <div>
                        <div className={css.yearText}>{yearName}</div>
                        <div className={css.monthText}>{monthName}</div>
                      </div>
                    )}
                    <div className={css.groupDateItem}>
                      <div
                        className={datePickerItem}
                        onMouseOver={overChanged(oldDate ? undefined : index)}
                        onClick={selectionChanged(oldDate ? undefined : index)}
                      />
                      <div className={css.dateText}>
                        {dd} / {mm}
                      </div>
                      {vacationName && (
                        <div className={vacationBox}>
                          <div className={css.vacationText}>{vacationName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}

        <div className={boudariesItem}></div>
      </div>
    </div>
  );
};

export default DatePicker;
