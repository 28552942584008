import React, { useEffect, useState } from "react";
import SelectList from "./select-list";
import Button from "./button";
import Header from "./header";
import css from "./screen.module.css";
import { useIntl } from "react-intl";
import useWindowSize from "../hooks/useWindowSize";

const defaultCountry = 39;

const ScreenCountry = ({
  selected,
  setSelected,
  nextScreen,
  previousScreen,
  gotoNext,
}) => {
  const [country, setCountry] = useState(
    selected ? selected.country : defaultCountry
  );
  const [countryList, setCountryList] = useState();
  const [departement, setDepartement] = useState(
    selected && selected.departement ? selected.departement : undefined
  );
  const [departementList, setDepartementsList] = useState();
  const [mode, setMode] = useState();
  const [countryOverlay, setCountryOverlay] = useState(false);
  const [departementOverlay, setDepartementOverlay] = useState(false);

  const intl = useIntl();
  const size = useWindowSize();

  useEffect(() => {
    setMode(size.width >= 900 ? "Desktop" : "Mobile");
  }, [size.width]);

  const validateChoices = () => {
    const result =
      country === defaultCountry ? { country, departement } : { country };
    setSelected(result);
    nextScreen();
  };

  useEffect(() => {
    const getData = async () => {
      const response = await fetch("/country.json");
      const data = await response.json();
      setCountryList(data);
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await fetch("/departements_fr.json");
      const data = await response.json();
      setDepartementsList(data);
    };

    getData();
  }, []);

  const isSelected = () =>
    country !== defaultCountry || departement !== undefined;

  return (
    <div className={css.screen}>
      <Header
        num={6}
        question={intl.messages.country.question}
        detail=""
        nextScreen={nextScreen}
        previousScreen={previousScreen}
        gotoNext={gotoNext}
      />
      <div>
        <img className={css.pingImage} src="ping/ping-flag.svg" alt="" />
      </div>
      <div className={css.columnBody}>
        <div className={css.selectLists}>
          {(mode === "Desktop" || !departementOverlay) && countryList && (
            <SelectList
              title={intl.messages.country.label1}
              choices={countryList}
              selected={country}
              setSelected={setCountry}
              overlay={countryOverlay}
              setOverlay={setCountryOverlay}
              icons={true}
            />
          )}
          {(mode === "Desktop" || !countryOverlay) && departementList && (
            <SelectList
              title={intl.messages.country.label2}
              choices={departementList}
              selected={departement}
              setSelected={setDepartement}
              overlay={departementOverlay}
              setOverlay={setDepartementOverlay}
              defaultText={intl.messages.country.defaultText2}
              disabled={country !== defaultCountry}
              hide={countryOverlay}
            />
          )}
        </div>
        {(mode === "Desktop" || (!countryOverlay && !departementOverlay)) && (
          <Button
            text={intl.messages.buttons.next}
            onClick={validateChoices}
            disabled={!isSelected()}
            hide={departementOverlay}
          />
        )}
      </div>
      {(countryOverlay || departementOverlay) && (
        <div className={css.tabletCorrection} />
      )}
      {(countryOverlay || departementOverlay) && (
        <div className={css.overlay} />
      )}
    </div>
  );
};

export default ScreenCountry;
