import React, { useState, useEffect } from "react";
import Button from "./button";
import DatePicker from "./date-picker";
import css from "./screen.module.css";
import Header from "./header";
import { useIntl } from "react-intl";
import useWindowSize from "../hooks/useWindowSize";

const ScreenDate = ({
  stayWeek,
  setStayWeek,
  setDeltaOrder,
  nextScreen,
  previousScreen,
  gotoNext,
}) => {
  const [mode, setMode] = useState();

  const intl = useIntl();
  const size = useWindowSize();

  useEffect(() => {
    setMode(size.width >= 1200 ? "Desktop" : "Mobile");
  }, [size.width]);

  return (
    <div className={css.screen}>
      <Header
        num={4}
        question={intl.messages.date.question}
        detail=""
        nextScreen={nextScreen}
        previousScreen={previousScreen}
        gotoNext={gotoNext}
      />
      <div>
        <img className={css.pingImage} src="ping/ping-go.svg" alt="" />
      </div>
      <div className={css.columnBody}>
        <DatePicker
          selected={stayWeek}
          setSelected={setStayWeek}
          setDeltaOrder={setDeltaOrder}
          mode={mode}
        />
        <Button
          text={intl.messages.buttons.next}
          onClick={nextScreen}
          disabled={stayWeek === undefined}
        />
      </div>
    </div>
  );
};

export default ScreenDate;
