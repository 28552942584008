import React from "react";
import css from "./screen.module.css";
import Header from "./header";
import Button from "./button";
import { useIntl } from "react-intl";

const ScreenFirstPage = ({ nextScreen }) => {
  const intl = useIntl();

  return (
    <div className={css.screen}>
      <Header
        num={-1}
        detail=""
        headerImage={intl.messages.intro.img}
        imgDescription={intl.messages.intro.imgDescription}
        lastScreen={true}
      />
      <div className={css.columnBody}>
        <div className={css.textIntro}>
          {intl.messages.intro.text1} <br />
          {intl.messages.intro.text2} <br />
          <br />
          {intl.messages.intro.text3} <br />
          {intl.messages.intro.text4} <br />
          {intl.messages.intro.text5} <br />
        </div>
        <Button
          text={intl.messages.buttons.doTest}
          onClick={nextScreen}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default ScreenFirstPage;
