import React from "react";
import classnames from "classnames";
import css from "./button.module.css";

const Button = ({ text, onClick, disabled, hide }) => {
  const buttonStyle = classnames(css.button, {
    [css.disabled]: disabled,
    [css.hide]: hide,
  });

  return (
    <button className={buttonStyle} onClick={onClick} disabled={disabled}>
      <img className={css.arrow} src="svg/white-arrow.svg" alt="" />
      <span className={css.text}>{text.toUpperCase()}</span>
    </button>
  );
};

export default Button;
