import React, { useState } from "react";
import classnames from "classnames";
import Selector from "./selector";
import css from "./screen.module.css";
import Header from "./header";
import { useIntl } from "react-intl";

const images = [
  "station/station-empty.svg",
  "station/station-small.svg",
  "station/station-medium.svg",
  "station/station-large.svg",
  "station/station-extra-large.svg",
];

const ScreenKmPistes = ({
  kmPiste,
  setKmPiste,
  nextScreen,
  previousScreen,
  gotoNext,
}) => {
  const [over, setOver] = useState();

  const intl = useIntl();

  return (
    <div className={css.screen}>
      <Header
        num={2}
        question={intl.messages.kmPiste.question}
        detail=""
        nextScreen={nextScreen}
        previousScreen={previousScreen}
        gotoNext={gotoNext}
      />
      <div>
        <img className={css.pingImage} src="ping/ping-see.svg" alt="" />
      </div>
      <div className={css.body}>
        <div className={css.stationImage}>
          {images.map((image, index) => {
            const imageClass = classnames(css.montain, {
              [css.open]:
                over === undefined
                  ? kmPiste === undefined
                    ? index === 0
                    : kmPiste === index - 1
                  : over === index - 1,
            });
            return (
              <img key={index} className={imageClass} src={image} alt="" />
            );
          })}
        </div>
        <Selector
          choices={intl.messages.kmPiste.selector}
          selected={kmPiste}
          setSelected={setKmPiste}
          over={over}
          setOver={setOver}
          nextScreen={nextScreen}
        />
      </div>
    </div>
  );
};

export default ScreenKmPistes;
