import React from "react";
import classnames from "classnames";
import css from "./selector.module.css";

const Selector = ({
  choices,
  selected,
  setSelected,
  over,
  setOver,
  nextScreen,
  symbol,
}) => {
  const selectionChanged = (code) => (e) => {
    setSelected(code);
    nextScreen();
  };
  const overChanged = (code) => (e) => {
    setOver(code);
  };

  return (
    <div className={css.selector} onMouseLeave={overChanged(undefined)}>
      {choices.map(({ code, name, text = "" }, index) => {
        const letter = String.fromCharCode(index + 65);
        const itemClasses = classnames(css.item, {
          [css.selected]: over === code || selected === code,
        });
        return (
          <div
            key={code}
            className={itemClasses}
            onClick={selectionChanged(code)}
            onMouseOver={overChanged(code)}
          >
            <div className={css.letterOuter}>
              <span className={css.letter}>{letter}</span>
            </div>
            <span>{name}</span>
            <div className={css.textOuter}>{text}</div>
            {symbol && (
              <div className={css.symbol}>
                {[...Array(index + 1).keys()].map((i) => (
                  <img className={css.image} key={i} src={symbol} alt="" />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Selector;
